/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface MoonrakerGCodeMetadata
 */
export interface MoonrakerGCodeMetadata {
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'estimated_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'filament_total'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadata
     */
    'filename'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'first_layer_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'gcode_end_byte'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'gcode_start_byte'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadata
     */
    'job_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'layer_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'modified'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'object_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'print_start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadata
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadata
     */
    'slicer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadata
     */
    'slicer_version'?: string;
    /**
     * 
     * @type {Array<MoonrakerGCodeMetadataThumbnailsInner>}
     * @memberof MoonrakerGCodeMetadata
     */
    'thumbnails'?: Array<MoonrakerGCodeMetadataThumbnailsInner>;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadata
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface MoonrakerGCodeMetadataThumbnailsInner
 */
export interface MoonrakerGCodeMetadataThumbnailsInner {
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadataThumbnailsInner
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerGCodeMetadataThumbnailsInner
     */
    'relative_path'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadataThumbnailsInner
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerGCodeMetadataThumbnailsInner
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface MoonrakerJob
 */
export interface MoonrakerJob {
    /**
     * 
     * @type {number}
     * @memberof MoonrakerJob
     */
    'end_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MoonrakerJob
     */
    'exists'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerJob
     */
    'filament_used'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerJob
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerJob
     */
    'job_id'?: string;
    /**
     * 
     * @type {MoonrakerGCodeMetadata}
     * @memberof MoonrakerJob
     */
    'metadata'?: MoonrakerGCodeMetadata;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerJob
     */
    'print_duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerJob
     */
    'start_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerJob
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerJob
     */
    'total_duration'?: number;
}
/**
 * 
 * @export
 * @interface MoonrakerPrinterObjectDisplayStatus
 */
export interface MoonrakerPrinterObjectDisplayStatus {
    /**
     * 
     * @type {string}
     * @memberof MoonrakerPrinterObjectDisplayStatus
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerPrinterObjectDisplayStatus
     */
    'progress'?: number;
}
/**
 * 
 * @export
 * @interface MoonrakerPrinterObjectPrintStats
 */
export interface MoonrakerPrinterObjectPrintStats {
    /**
     * 
     * @type {number}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'filament_used'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'print_duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerPrinterObjectPrintStats
     */
    'total_duration'?: number;
}
/**
 * 
 * @export
 * @interface MoonrakerPrinterObjectVirtualSDCard
 */
export interface MoonrakerPrinterObjectVirtualSDCard {
    /**
     * 
     * @type {boolean}
     * @memberof MoonrakerPrinterObjectVirtualSDCard
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MoonrakerPrinterObjectVirtualSDCard
     */
    'progress'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MoonrakerPrinterState = {
    KlippyStartup: 'klippy_startup',
    KlippyShutdown: 'klippy_shutdown',
    KlippyError: 'klippy_error',
    KlippyDisconnected: 'klippy_disconnected',
    Ready: 'ready',
    PrePrint: 'pre_print',
    Printing: 'printing',
    Pause: 'pause',
    Error: 'error',
    Disconnected: 'disconnected',
    Unknown: 'unknown',
    InternalError: 'internal_error'
} as const;

export type MoonrakerPrinterState = typeof MoonrakerPrinterState[keyof typeof MoonrakerPrinterState];


/**
 * 
 * @export
 * @interface WebAPIErrorResp
 */
export interface WebAPIErrorResp {
    /**
     * 
     * @type {string}
     * @memberof WebAPIErrorResp
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface WebPrinter
 */
export interface WebPrinter {
    /**
     * 
     * @type {boolean}
     * @memberof WebPrinter
     */
    'allow_no_register_print'?: boolean;
    /**
     * 
     * @type {MoonrakerPrinterObjectDisplayStatus}
     * @memberof WebPrinter
     */
    'display_status'?: MoonrakerPrinterObjectDisplayStatus;
    /**
     * 
     * @type {string}
     * @memberof WebPrinter
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebPrinter
     */
    'last_update_time'?: number;
    /**
     * 
     * @type {MoonrakerJob}
     * @memberof WebPrinter
     */
    'latest_job'?: MoonrakerJob;
    /**
     * 
     * @type {MoonrakerGCodeMetadata}
     * @memberof WebPrinter
     */
    'loaded_file'?: MoonrakerGCodeMetadata;
    /**
     * 
     * @type {string}
     * @memberof WebPrinter
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebPrinter
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebPrinter
     */
    'no_pause_duration'?: number;
    /**
     * 
     * @type {MoonrakerPrinterObjectPrintStats}
     * @memberof WebPrinter
     */
    'printer_stats'?: MoonrakerPrinterObjectPrintStats;
    /**
     * 
     * @type {string}
     * @memberof WebPrinter
     */
    'registered_job_id'?: string;
    /**
     * 
     * @type {MoonrakerPrinterState}
     * @memberof WebPrinter
     */
    'state'?: MoonrakerPrinterState;
    /**
     * 
     * @type {string}
     * @memberof WebPrinter
     */
    'url'?: string;
    /**
     * 
     * @type {MoonrakerPrinterObjectVirtualSDCard}
     * @memberof WebPrinter
     */
    'virtual_sd_card'?: MoonrakerPrinterObjectVirtualSDCard;
}


/**
 * 
 * @export
 * @interface WebUpdatePrinterResponse
 */
export interface WebUpdatePrinterResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WebUpdatePrinterResponse
     */
    'allow_no_reg_print'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebUpdatePrinterResponse
     */
    'reg_job_id'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ping/Pong
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ping/Pong
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.pingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Ping/Pong
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<string> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Ping/Pong
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pingGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrintersApi - axios parameter creator
 * @export
 */
export const PrintersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/printers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the printers
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyGet: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('printersKeyGet', 'key', key)
            const localVarPath = `/printers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get thumbnail for a file
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyLatestThumbGet: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('printersKeyLatestThumbGet', 'key', key)
            const localVarPath = `/printers/{key}/latest_thumb`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a printer
         * @param {string} key key of printer
         * @param {string} [regJobId] jobId of registered job
         * @param {boolean} [allowNoRegPrint] allow printing without registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyPut: async (key: string, regJobId?: string, allowNoRegPrint?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('printersKeyPut', 'key', key)
            const localVarPath = `/printers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regJobId !== undefined) {
                localVarQueryParameter['regJobId'] = regJobId;
            }

            if (allowNoRegPrint !== undefined) {
                localVarQueryParameter['allowNoRegPrint'] = allowNoRegPrint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintersApi - functional programming interface
 * @export
 */
export const PrintersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebPrinter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintersApi.printersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the printers
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printersKeyGet(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebPrinter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printersKeyGet(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintersApi.printersKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get thumbnail for a file
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printersKeyLatestThumbGet(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printersKeyLatestThumbGet(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintersApi.printersKeyLatestThumbGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a printer
         * @param {string} key key of printer
         * @param {string} [regJobId] jobId of registered job
         * @param {boolean} [allowNoRegPrint] allow printing without registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printersKeyPut(key: string, regJobId?: string, allowNoRegPrint?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebUpdatePrinterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.printersKeyPut(key, regJobId, allowNoRegPrint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrintersApi.printersKeyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PrintersApi - factory interface
 * @export
 */
export const PrintersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersGet(options?: any): AxiosPromise<Array<WebPrinter>> {
            return localVarFp.printersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the printers
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyGet(key: string, options?: any): AxiosPromise<WebPrinter> {
            return localVarFp.printersKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get thumbnail for a file
         * @param {string} key key of printer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyLatestThumbGet(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.printersKeyLatestThumbGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a printer
         * @param {string} key key of printer
         * @param {string} [regJobId] jobId of registered job
         * @param {boolean} [allowNoRegPrint] allow printing without registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersKeyPut(key: string, regJobId?: string, allowNoRegPrint?: boolean, options?: any): AxiosPromise<WebUpdatePrinterResponse> {
            return localVarFp.printersKeyPut(key, regJobId, allowNoRegPrint, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintersApi - object-oriented interface
 * @export
 * @class PrintersApi
 * @extends {BaseAPI}
 */
export class PrintersApi extends BaseAPI {
    /**
     * 
     * @summary Get list of printers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintersApi
     */
    public printersGet(options?: RawAxiosRequestConfig) {
        return PrintersApiFp(this.configuration).printersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the printers
     * @param {string} key key of printer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintersApi
     */
    public printersKeyGet(key: string, options?: RawAxiosRequestConfig) {
        return PrintersApiFp(this.configuration).printersKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get thumbnail for a file
     * @param {string} key key of printer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintersApi
     */
    public printersKeyLatestThumbGet(key: string, options?: RawAxiosRequestConfig) {
        return PrintersApiFp(this.configuration).printersKeyLatestThumbGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a printer
     * @param {string} key key of printer
     * @param {string} [regJobId] jobId of registered job
     * @param {boolean} [allowNoRegPrint] allow printing without registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintersApi
     */
    public printersKeyPut(key: string, regJobId?: string, allowNoRegPrint?: boolean, options?: RawAxiosRequestConfig) {
        return PrintersApiFp(this.configuration).printersKeyPut(key, regJobId, allowNoRegPrint, options).then((request) => request(this.axios, this.basePath));
    }
}



